<template>
	<div class="login">
		<div class="login_back gradientColor">
			<div class="login_back_bottom gradientColor"></div>
		</div>
		<transition name="fade-left" appear translate>
			<component
				:is="curLogin"
	            @toggleLogin="clickToggleLogin"
			></component>
		</transition>
	</div>
</template>

<script>
import vipPhoneLogin from './components/vip-phone-login'
import vipIdCardLogin from './components/vip-idcard-login'
export default {
	name: 'viplogin',
	components: { vipPhoneLogin, vipIdCardLogin },
	props: {
		msg: String
	},
	data: () => ({
		curLogin: 'vipPhoneLogin'
	}),
	mounted() {
		const appNo = sessionStorage.getItem('appno') || 'APP02';
		if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null) {
			this.$router.push({path: '/viphome', query: {appNo}})
		}
		if (this.$route.query.state === 'single') {
			// 单点登录
			this.curLogin = vipIdCardLogin;
		}
	},
	methods: {
		clickToggleLogin (loginName) {
			this.curLogin = loginName
		},
	},
}
</script>

<style scoped lang="less">
.login {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	.login_back {
		position: relative;
		width: 100%;
		height: 195px;
		.login_back_bottom{
			width: 110%;
			height: 120px;
			border-radius: 0 0 50% 50%;
			position:absolute;
			left: -5%;
			bottom: -60px;
		}
	}
	.gradient{
		background-image: linear-gradient(to left, #fca142, #fca142) !important;
	}
}
.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;
	.login{
		.gradient{
			background-image: linear-gradient(to left, @colorMain, @colorMain) !important;
		}
	}
}
.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;
	.login{
		.gradient{
			background-image: linear-gradient(to left, @colorMain, @colorMain) !important;
		}
	}
}
</style>
<style lang="less">
	.login {
		.login_layout {
			width: 100%;
			position: absolute;
			left: 0;
			top: 85px;
			padding: 0 26px;
			.main {
				height: 370px;
				background-color:#FFF;
				border-radius: 10px;
				box-shadow: 0px 5px 7px 1px rgba(216, 216, 216, 0.25);
				position: relative;
				.main_portrait {
					position: absolute;
					width: 100%;
					top: -37px;
					>.box {
						margin: 0 auto;
						width: 74px;
						height: 74px;
						border: 2px solid #fff;
						border-radius: 50%;
						box-shadow: 0px 1px 2px 0px #fca142;
					}
				}
				.main_body {
					padding: 80px 26px 0;
					.title {
						font-size: 22px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #141827;
						margin-top: 0;
						margin-bottom: 17px;
					}
					.form {
						.van-field {
							padding: 0;
							background-color: #F5F5F5;
							border-radius: 20px;
							height: 40px;
							display: flex;
							align-items: center;
						}
						.van-field + .van-field { margin-top: 12px; }
						.sort-icon{
							width:40px;
							display: flex;
							align-items: center;
							justify-content: flex-end;
						}
						.other {
							text-align: right;
							.la-btn {
								margin-top: 12px;
								padding: 4px 13px;
							}
						}
						.btn{
							width: 100%;
							margin-top: 30px;
						}
						.explain{
							.text { color: #999; }
							>span {
								display: inline-block;
								vertical-align: middle;
								&.radio {
									width: 14px;
									height: 14px;
									border-radius: 50%;
									position: relative;
									text-align: center;
									>span{
										width: 8px;
										height: 8px;
										border-radius: 50%;
										position:absolute;
										top: 2px;
										left: 2px;
									}
								}
								& + span{
									width: calc(100% - 14px);
									font-size: 12px;
									padding-left: 6px;
								}
							}
						}
					}
				}
			}
			.other_login{
				padding-top: 27px;
				margin: 0 auto;
				.divider {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					>div {
						font-size: 13px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #BFBFBF;
						&.text{ padding: 0 5px; }
						&.line {
							width: 80px;
							height: 1px ;
							background-color: #BFBFBF;
							position: relative;
							&:before {
								content: '';
								position: absolute;
								top: -2px;
								height: 5px;
								width: 5px;
								border-radius: 50%;
								background-color:  #BFBFBF;
							}
							&.left:before { right: 0; }
							&.right:before { left: 0; }
						}
					}
				}
				.login_option{
					display: flex;
					align-items: center;
					justify-content: center;
					>div{
						text-align: center;
						img { width: 50px; height: 50px; }
						p{ margin: 13px 0 0; padding: 0; color: #3E4A59;font-family: PingFang SC;opacity: 0.89; font-size: 12px;}
					}
				}
			}
		}
	}
	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;
		.login {
			.login_layout {
				.main {
					.main_portrait {
						>.box {
							margin: 0 auto;
							width: 74px;
							height: 74px;
							border: 2px solid #fff;
							border-radius: 50%;
							box-shadow: 0px 1px 2px 0px @colorMain;
						}
					}
				}
			}
		}
	}
	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;
		.login {
			.login_layout {
				.main {
					.main_portrait {
						>.box {
							margin: 0 auto;
							width: 74px;
							height: 74px;
							border: 2px solid #fff;
							border-radius: 50%;
							box-shadow: 0px 1px 2px 0px @colorMain;
						}
					}
				}
			}
		}
	}
</style>
